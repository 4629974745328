import React from "react";
import { useState} from "react";

const RecordCell = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const startEdit = () => {
        setIsEditing(true);
    }
    
    return(
        <>
            <tr className="recordCell">
                <td>{props.name}</td>
                <td>{props.company}</td>
                <td>{props.package}</td>
                <td onClick={startEdit}><i class="fa-solid fa-pencil fa-la"></i>
                </td>
            </tr>
        </>
    );
}

export default RecordCell;