import "../node_modules/bootstrap/dist/js/bootstrap"
import './App.css';

import Home from './pages/Home';
import Sidebar from "./components/Sidebar";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Institutions from "./pages/Institutions";
import Rankings from "./components/ListPage/Rankings";
import Login from "./pages/Login";
import React, { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import Upload from "./pages/Upload";
import Mission from "./pages/Mission";
import { REACT_APP_BACKEND_URI } from "./config";


function App(){
  const [isLogin, setLogin] = useState(false);
  const [LoginUser, setLoginUser] = useState(null);
  const [univId, setUnivId] = useState(null);    
  const [specialType, setSpecialType] = useState(null);

  const setUserFunc = (user) => {
    setLoginUser(user);
    setLogin(true);
    getUserType(user);
  }

  useEffect(() => {
    if(specialType!=null)
    console.log('Special Type Set:', specialType);    
}, [specialType]);

  const getUserType = async (accessToken) => {
    console.log("fetching user type", accessToken)
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URI}/auth/get-user-type`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          token: accessToken
        })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user type');
      }
      const type = await response.json();

      console.log('User Type:', type);
      setSpecialType(type);
      if(type === false){
        getUnivId(accessToken);
      }

    }
    catch (error) {
      console.error('Error fetching user type:', error);
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User:', user.uid); 
        getUserType(user.accessToken);
        setLoginUser(user.accessToken);
        setLogin(true);
        // getUnivId(user);
      } else {
        setLoginUser(null);
        setLogin(false);
      }
    });
    return () => unsubscribe();
  });



  const getUnivId = async (token) => {
    console.log("fetching login information", token)
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URI}/university/getUnivId`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ 
          token: token
        })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch university details');
      }
      const data = await response.json();
      await setUnivId(data.univId);
      await setLoginUser(token);
      await setLogin(true);
      console.log('UnivId:', data.univId);
    } catch (error) {
      console.error('Error fetching university details:', error);
    }
  }

  const minimiseSidebar = ()=>{
    let sidebar = document.getElementById('sidebar');
    let sideNames = document.getElementsByClassName('sideNames');
    let main = document.getElementsByClassName('main')[0];
    let navbar = document.getElementsByClassName('newNavbar')[0];
    let mainCover = document.getElementById('mainCover');

  
   
    mainCover.style.display = 'none';
    if(sidebar.style.width==='200px'){
      
        for(let i=0; i<sideNames.length; i++){
            sideNames[i].style.display = 'none';
        }
        if(window.innerWidth>720){
          main.style.marginLeft = '60px';
          sidebar.style.width = '60px';
          navbar.style.paddingLeft = '60px';
        }
        else{
          main.style.marginLeft = '0px';
          sidebar.style.width = '0px';
          navbar.style.paddingLeft = '0px';
        }
        
    }
    else {
        for(let i=0; i<sideNames.length; i++){
            sideNames[i].style.display = 'block';
        }
        sidebar.style.width = '200px';
        if(window.innerWidth>720){
            main.style.marginLeft = '200px';
            navbar.style.paddingLeft = '200px';
        }
        else{
          mainCover.style.display = 'block';
        }
    }
}

let shouldDelay=false;
let resizeTimer = null;

window.addEventListener('resize', function() {
  if(!shouldDelay){
    minimiseSidebar();
    shouldDelay=true;
    if(resizeTimer==null){
      resizeTimer = setTimeout(function() {
        shouldDelay=false;
        resizeTimer = null;
      }, 20); 
    }
  }
});

  return (
    <> 
      <Router>

        { <div className="wrapper">
          <div className="newNavbar">
              <span id="sidebarBut" onClick={minimiseSidebar}><i className="fa-solid fa-bars fa-xl"></i></span>    
              <span className='navheading'>Uni-Saaransh Dashboard</span>  
          </div>
          <div className="main">
            {/* <Navbar /> */}
            {!isLogin && <Routes>
              <Route exact path="/" element={<Login setUser = {setUserFunc} />} />
              <Route exact path="/rankings" element={<Login setUser = {setUserFunc} />} />
              <Route exact path="/institutions/:univId" element={<Login setUser = {setUserFunc} />} />
              <Route exact path="/contact" element={<Login setUser = {setUserFunc} />} />
              <Route exact path="/about-us" element={<Login setUser = {setUserFunc} />} />
              <Route exact path="/team" element={<Login setUser = {setUserFunc} />} />
              <Route exact path="/login" element={ <Login setUser = {setUserFunc}/>} />
            </Routes>}
            {isLogin && <Routes>      
              <Route exact path="/login" element={<Navigate to='/' />} />
              <Route exact path="/" element={<Home />} />
              {specialType!=null && specialType && <Route exact path="/rankings" element={<Rankings />} />}
              {specialType!=null && specialType && <Route exact path="/institutions/:univId" element={<Institutions />} />}
              <Route exact path="/contact" element={<Contact/>} />
              <Route exact path='/mission' element={<Mission/>}/>
              <Route exact path='/about-us' element={<Mission/>}/>
              <Route exact path='/team' element={<Mission/>}/>
              {specialType!=null && !specialType && <Route exact path="/profile" element={<Upload idToken = {LoginUser} univId = {univId}/>} />}
            </Routes>}
            <Footer/>
          </div>
          <Sidebar isLogin = {isLogin} setLogin={setLogin} specialType = {specialType}/>
          <div id="mainCover" onClick={minimiseSidebar}></div>
        </div>}
        
      </Router>

      
    </>
  );
}

export default App;