import React, { useEffect, useState } from 'react';
import '../css/Login.css';
import { auth, app } from '../firebaseConfig';
import { signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth';
import { REACT_APP_BACKEND_URI } from '../config';

const Login = (props) => {
  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(true);
  const [customToken, setCustomToken] = useState('');
  const [userType, setUserType] = useState('');
  const [localhost, setLocalhost] = useState('unisaaransh.org');

  const handleToggle = () => {
    setIsRegisterFormVisible(!isRegisterFormVisible);
  };


  useEffect(() => {
    console.log('Custom token useEffect:', customToken);
    if(customToken.length > 0)
    signInWithCustomTokenAndGetIdToken(customToken);
    
  }, [customToken]);

  async function signInWithCustomTokenAndGetIdToken(customToken) {
    try {
      await signInWithCustomToken(auth, customToken);
      const idToken = await auth.currentUser.getIdToken();
      console.log('ID Token:', idToken);
      props.setUser(idToken);
      return idToken;
    } catch (error) {
      console.error('Error signing in with custom token:', error);
      throw error;
    }
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());
    const trimmedValues = Object.keys(formValues).reduce((acc, key) => {
        acc[key] = formValues[key].trim();
        return acc;
    }, {});

    const email = trimmedValues.email || '';
    const password = trimmedValues.password || '';
    const alertBox = document.querySelector('.login-alert-box');

    // Assuming univId and password are state variables bound to form inputs
    const loginData = {
      email: email,
      password: password,
    };

    let userCredential='';
     try {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      } catch(error) {
        console.error('Login Error:', error);
        alertBox.innerHTML = 'Login Error';
        alertBox.style.display = 'block';
        return;
      }
      const idToken = await userCredential.user.getIdToken();
      try {
        const response = await fetch(`${REACT_APP_BACKEND_URI}/auth/login`, { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ idToken }),
        });
      
        const data = await response.json();
      
        if (data.error) {
          alertBox.innerHTML = data.message || 'An error occurred. Please try again later';
          alertBox.style.display = 'block';
        } else {
          console.log(data);
          alertBox.innerHTML = 'Login successful';
          alertBox.style.display = 'block';
        }
      } catch (error) {
        console.error('Error:', error);
        alertBox.innerHTML = 'An error occurred. Please try again later';
        alertBox.style.display = 'block';
      }
  }

 const handleRegister = (event) => {
    event.preventDefault();
	alert("Registering..");
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());
    const trimmedValues = Object.keys(formValues).reduce((acc, key) => {
        acc[key] = formValues[key].trim();
        return acc;
    }, {});
    const userType = trimmedValues.userType || '';
    const email = trimmedValues.email || '';
    
    const alertBox = document.querySelector('.alert-box');


    if (email === '' || userType === '') {
      alertBox.innerHTML = 'All fields are required';
      alertBox.style.display = 'block';
      return;
    }

    let data;

    if(userType=='special'){
      data = {
        universityName: 'Not Applicable',
        email: email,
        univId: 'Not Applicable',
        specialType: true,
      };
    }
    else{
      const universityName = trimmedValues.universityName || '';
      const univId =Math.random().toString(36) + Math.random().toString(36).substr(2, 32).toUpperCase();
      // const password = trimmedValues.password || '';
      // const cpassword = trimmedValues.cpassword || '';

      if (universityName === '' || email === ''|| userType === '') {
        alertBox.innerHTML = 'All fields are required';
        alertBox.style.display = 'block';
        return;
      }
      // if (password !== cpassword) {
      //   alertBox.innerHTML = 'Passwords do not match';
      //   alertBox.style.display = 'block';
      //   return;
      // }

      // Check if univId is alphanumeric and does not contain spaces
      // Prepare the data object from form values
      data = {
        universityName: universityName,
        email: email,
        univId: univId,
        specialType: false,
      };
    }

    // Send a POST request to the server
    fetch(`${REACT_APP_BACKEND_URI}/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {      
      if (data.error) {
        alertBox.innerHTML = data.message || 'An error occurred. Please try again later';
        alertBox.style.display = 'block';
      } else {
        alertBox.innerHTML = 'Registration successful';
        alertBox.style.display = 'block';
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alertBox.innerHTML = 'An error occurred. Please try again later';
    });

};

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={handleLogin}>
        <div className="login-alert-box"></div>
        <div className="logo"></div>
        <input type="email" name='email' placeholder="Email"  />
        <input type="password" name='password' placeholder="Password"  />
        <div className="options">
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;