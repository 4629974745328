import React, { useEffect, useState } from "react";
import axios from "axios";
import noProfile from "../../res/images/noProfile.png";
import noBanner from "../../res/images/noBanner.png";
import { REACT_APP_BACKEND_URI } from "../../config";
import { RiTwitterXFill } from "react-icons/ri";
import { GrInstagram } from "react-icons/gr";
import { ImFacebook } from "react-icons/im";
import { LiaLinkedinIn } from "react-icons/lia";

const BasicInfo = (props) => {
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [emails, setEmails] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgType, setImgType] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState(
    props.profileImg || ""
  );
  const [bannerImageUrl, setBannerImageUrl] = useState(props.bannerImg || "");
  const [uploading, setUploading] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [basicDetails, setBasicDetails] = useState({
    univId: "Not Loaded",
    name: "Not Loaded",
    description: "Not Loaded",
    profileImg: null,
    bannerImg: null,
    city: "Not Loaded",
    state: "Not Loaded",
    email: ["Not Loaded"],
    phone: ["0000000000"],
    website: "No Website Provided",
    linkedin: "No Website Provided",
    facebook: "No Website Provided",
    instagram: "No Website Provided",
    twitter: "No Website Provided",
  });

  const fetchBasicInfo = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URI}/university/get-basic-info/${props.univId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch university details");
      }
      const data = await response.json();
      setBasicDetails(data);
      setCanSave(true);
      console.log("UnivId:", data.univId);
    } catch (error) {
      console.error("Error fetching university details:", error);
    }
  };

  const updateBasicInfo = async () => {
    if (!canSave) return;
    try {
      console.log("Updating Database", basicDetails);
      const response = await fetch(
        `${REACT_APP_BACKEND_URI}/university/update-basic-info`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idToken: props.idToken,
            updateData: basicDetails,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update university details");
      }
      const data = await response.json();
      console.log("Updated university details:", data);
    } catch (error) {
      console.error("Error updating university details:", error);
    }
  };

  async function updateAll(
    pname,
    pdescription,
    pcity,
    pstate,
    contactArray,
    emailArray,
    pwebsite,
    plinkedin,
    pfacebook,
    pinstagram,
    ptwitter
  ) {
    setBasicDetails((prevData) => ({
      ...prevData,
      univId: basicDetails.univId,
      name: pname,
      description: pdescription,
      profileImg: basicDetails.profileImg,
      bannerImg: basicDetails.bannerImg,
      city: pcity,
      state: pstate,
      email: emailArray,
      phone: contactArray,
      website: pwebsite,
      linkedin: plinkedin,
      facebook: pfacebook,
      instagram: pinstagram,
      twitter: ptwitter,
    }));
  }

  const handleLocalEdit = () => {
    const pname = document.getElementById("profileUnivName").value;
    const pdescription = document.getElementById("profileDescription").value;
    const pcity = document.getElementById("profilecity").value;
    const pstate = document.getElementById("profileState").value;
    const pwebsite = document.getElementById("profileWebsite").value; // Add website input
    const plinkedin = document.getElementById("profilelinkedin").value; // Add website input
    const pfacebook = document.getElementById("profilefb").value; // Add website input
    const pinstagram = document.getElementById("profileinsta").value; // Add website input
    const ptwitter = document.getElementById("profiletweet").value; // Add website input
    let pcontact = document.getElementById("profileContact").value.trim();
    let pemail = document.getElementById("profileEmail").value.trim();

    pcontact = pcontact.replace(/\s+/g, " ");
    let contactArray = pcontact.split(",").map((item) => item.trim());

    pemail = pemail.replace(/\s+/g, " ");
    let emailArray = pemail.split(",").map((item) => item.trim());

    updateAll(
      pname,
      pdescription,
      pcity,
      pstate,
      contactArray,
      emailArray,
      pwebsite,
      plinkedin,
      pfacebook,
      pinstagram,
      ptwitter
    ); // Pass website
  };

  const fetchNumbers = async () => {
    const concatenatedNumbers =
      basicDetails.phone && basicDetails.phone.length > 0
        ? basicDetails.phone
            .filter((number) => number && number.trim() !== "")
            .join(", ")
        : "";
    setPhoneNumbers(concatenatedNumbers);

    const concatenatedEmails =
      basicDetails.email && basicDetails.email.length > 0
        ? basicDetails.email
            .filter((email) => email && email.trim() !== "")
            .join(", ")
        : "";
    setEmails(concatenatedEmails);
  };

  useEffect(() => {
    fetchBasicInfo();
  }, [props.univId]);

  useEffect(() => {
    fetchNumbers();
    console.log("Basic Details Updated:", basicDetails);
    updateBasicInfo();
  }, [basicDetails]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      // Check if file is an image
      setSelectedFile(file);
      console.log(file);
    } else {
      alert("Please select an image file");
      event.target.value = ""; // Clear the input if invalid file type is selected
    }
  };

  const handleUploadImg = async () => {
    if (!selectedFile) {
      alert("No file selected");
      return;
    }
    if (!canSave) {
      alert("Couldn't save the data. Please try again");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("idToken", props.idToken);
    formData.append("imgType", imgType);

    try {
      setUploading(true);
      const response = await axios.post(
        `${REACT_APP_BACKEND_URI}/university/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      alert("File uploaded successfully");

      const url = URL.createObjectURL(selectedFile);
      if (imgType === "profileImg") setProfileImageUrl(url);
      else setBannerImageUrl(url);

      setUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
      setUploading(false);
    }
  };

  const uploadImgOpen = (typeImg) => {
    console.log("Upload Image");

    setImgType(typeImg);

    const box = document.getElementsByClassName("profileImgUpload");
    if (box.length > 0) {
      const isCurrentlyVisible = box[0].style.display === "flex";

      for (let i = 0; i < box.length; i++) {
        box[i].style.display = isCurrentlyVisible ? "none" : "flex";
      }
    }
  };

  const toggleDisplay = () => {
    let orig = document.getElementById("basicInfo");
    let cont = document.getElementById("basicEditContainer");

    if (!editMode) {
      let name = document.getElementById("profileUnivName");
      let description = document.getElementById("profileDescription");
      let city = document.getElementById("profilecity");
      let state = document.getElementById("profileState");
      let contact = document.getElementById("profileContact");
      let email = document.getElementById("profileEmail");

      name.value = basicDetails.name;
      description.value = basicDetails.description;
      city.value = basicDetails.city;
      state.value = basicDetails.state;
      contact.value = basicDetails.phone ? basicDetails.phone.join(", ") : "";
      email.value = basicDetails.email ? basicDetails.email.join(", ") : "";

      cont.style.display = "block";
      orig.style.display = "none";
    } else {
      handleLocalEdit();
      cont.style.display = "none";
      orig.style.display = "block";
    }
    setEditMode(!editMode);
    fetchNumbers();
  };

  function handleInputChange(event) {
    const { id, value } = event.target;
    let targetId = "";

    switch (id) {
      case "profileUnivName":
        targetId = "basicName";
        break;
      case "profileDescription":
        targetId = "basicDescription";
        break;
      case "profilecity":
        targetId = "basiccity";
        break;
      case "profileState":
        targetId = "basicState";
        break;
      case "profileContact":
        targetId = "basicContact";
        break;
      case "profileEmail":
        targetId = "basicEmail";
        break;
      case "profileWebsite":
        targetId = "basicWebsite";
        break;
      case "profilelinkedin":
        targetId = "basicLinkedIn";
        break;
      case "profilefb":
        targetId = "basicFacebook";
        break;
      case "profileinsta":
        targetId = "basicInstagram";
        break;
      case "profiletweet":
        targetId = "basicTwitter";
        break;
      default:
        console.warn("Unhandled input ID:", id);
        return;
    }

    const targetElement = document.getElementById(targetId);
    if (targetElement && value) {
      targetElement.innerHTML = value;
    } else {
      console.error("Element not found for ID:", targetId);
    }
  }

  return (
    <div className="basicInfoContainer">
      <div className="profilebanner">
        <div className="profileImgUpload">
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <button onClick={handleUploadImg} disabled={uploading}>
            {uploading ? "Uploading..." : "Upload"}
          </button>
        </div>
        {basicDetails.bannerImg != null && (
          <img src={basicDetails.bannerImg} alt="Banner" />
        )}
        {!props.readonly && (
          <span className="editBut" onClick={() => uploadImgOpen("bannerImg")}>
            <i className="fa-solid fa-pen-nib fa-la"></i>
          </span>
        )}
      </div>

      <div className="profileImg">
        {basicDetails.profileImg != null && (
          <img src={basicDetails.profileImg} alt="Profile" />
        )}
        {!props.readonly && (
          <span className="editBut" onClick={() => uploadImgOpen("profileImg")}>
            <i className="fa-solid fa-pen-nib fa-la"></i>
          </span>
        )}
      </div>

      <div className="basicInfo" id="basicInfo">
        {!props.readonly && (
          <span className="editBut" onClick={toggleDisplay}>
            <i className="fa-solid fa-pen-nib fa-la"></i>
          </span>
        )}

        <div className="name" id="basicName">
          <span>{basicDetails.name || "No Name Provided"}</span>
        </div>

        <div className="description" id="basicDescription">
          <div style={{ whiteSpace: "pre-line" }}>
            {basicDetails.description || "No Description Provided"}
          </div>
        </div>

        <div className="city contact">
          <span className="contactInfo">Address: </span>
          <span id="basiccity">{basicDetails.city || "No City Provided"}</span>,
          <span id="basicState">
            {basicDetails.state || "No State Provided"}
          </span>
        </div>

        <div className="contact" style={{ display: 'flex', alignItems: 'center' }}>
		<div className="website" style={{ marginRight: '20px' }}>
            <span className="contactInfo">Website: </span>
            <span id="basicWebsite">
              {basicDetails.website || "No Website Provided"}
            </span>
          </div>

          <div className="social-icons"  style={{ border: '2px solid #ccc', padding: '10px', borderRadius: '5px', display: 'flex', gap: '10px' }}>
            <a
              href={basicDetails.linkedin}
              target="_blank"
              rel="noopener noreferrer"
			  style={{ marginRight: '10px' }}
            >
              <LiaLinkedinIn size={24} />
            </a>
            <a
              href={basicDetails.facebook}
              target="_blank"
              rel="noopener noreferrer"
			  style={{ marginRight: '10px' }}
            >
              <ImFacebook size={24} />
            </a>
            <a
              href={basicDetails.instagram}
              target="_blank"
              rel="noopener noreferrer"
			  style={{ marginRight: '10px' }}
            >
              <GrInstagram size={24} />
            </a>
            <a
              href={basicDetails.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RiTwitterXFill size={24} />
            </a>
          </div>
        </div>

        <div className="contact">
          <span className="contactInfo">Contact: </span>
          <span className="contactValue" id="basicContact">
            {basicDetails.phone && basicDetails.phone.length > 0
              ? basicDetails.phone
                  .filter((number) => number && number.trim() !== "")
                  .map((number, index) =>
                    index === basicDetails.phone.length - 1
                      ? number
                      : number + ", "
                  )
              : "No Contact Provided"}
          </span>
          <br />
		  <br />
          <span className="contactInfo">Email: </span>
          <span className="contactValue" id="basicEmail">
            {basicDetails.email && basicDetails.email.length > 0
              ? basicDetails.email
                  .filter((email) => email && email.trim() !== "")
                  .map((email, index) =>
                    index === basicDetails.email.length - 1
                      ? email
                      : email + ", "
                  )
              : "No Email Provided"}
          </span>
        </div>
      </div>

      <div id="basicEditContainer">
        <span className="editBut" onClick={toggleDisplay}>
          <i className="fa-solid fa-check fa-la"></i>
        </span>
        <form className="editContainer">
          <label htmlFor="univname">University Name: </label>
          <input
            type="text"
            id="profileUnivName"
            name="univname"
            placeholder="University Name"
            onInput={handleInputChange}
          />
          <label htmlFor="description">Description: </label>
          <textarea
            id="profileDescription"
            name="description"
            placeholder="Description"
            onInput={handleInputChange}
          />
          <label htmlFor="city">Address: </label>
          <input
            type="text"
            id="profilecity"
            name="location"
            placeholder="City"
            onInput={handleInputChange}
          />
          <label htmlFor="state">State: </label>
          <input
            type="text"
            id="profileState"
            name="state"
            placeholder="State"
            onInput={handleInputChange}
          />
          <label htmlFor="contact">Contact: </label>
          <input
            type="text"
            id="profileContact"
            name="contact"
            placeholder="Contact"
            onInput={handleInputChange}
          />
          <label htmlFor="email">Email Ids: </label>
          <input
            type="text"
            id="profileEmail"
            name="email"
            placeholder="Email IDs"
            onInput={handleInputChange}
          />
          <label htmlFor="website">Website: </label>
          <input
            type="text"
            id="profileWebsite"
            name="website"
            placeholder="Website URL"
            onInput={handleInputChange}
          />
          <label htmlFor="linkedin">Linkedin: </label>
          <input
            type="text"
            id="profilelinkedin"
            name="website"
            placeholder="Linkedin URL"
            onInput={handleInputChange}
          />
          <label htmlFor="facebook">Facebook: </label>
          <input
            type="text"
            id="profilefb"
            name="website"
            placeholder="Facebook URL"
            onInput={handleInputChange}
          />
          <label htmlFor="instagram">Instagram: </label>
          <input
            type="text"
            id="profileinsta"
            name="website"
            placeholder="Instagram URL"
            onInput={handleInputChange}
          />
          <label htmlFor="twitter">Twitter: </label>
          <input
            type="text"
            id="profiletweet"
            name="website"
            placeholder="Twitter URL"
            onInput={handleInputChange}
          />
        </form>
      </div>
    </div>
  );
};

export default BasicInfo;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import noProfile from '../../res/images/noProfile.png';
// import noBanner from '../../res/images/noBanner.png';
// import { REACT_APP_BACKEND_URI } from '../../config';

// const BasicInfo = (props) => {
//     const [phoneNumbers, setPhoneNumbers] = useState('');
//     const [emails, setEmails] = useState('');
//     const [editMode, setEditMode] = useState(false);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [imgType, setImgType] = useState('');
//     const [profileImageUrl, setProfileImageUrl] = useState(props.profileImg || '');
//     const [bannerImageUrl, setBannerImageUrl] = useState(props.bannerImg || '');
//     const [uploading, setUploading] = useState(false);
//     const [canSave, setCanSave] = useState(false);
//     const [basicDetails, setBasicDetails] = useState({
//         univId: 'Not Loaded',
//         name: 'Not Loaded',
//         description: 'Not Loaded',
//         profileImg: null,
//         bannerImg: null,
//         city: 'Not Loaded',
//         state: 'Not Loaded',
//         email: ['Not Loaded'],
//         phone: ['0000000000'],
//         website: null,
//     });

//     const fetchBasicInfo = async () => {
//         try {
//             const response = await fetch(`${REACT_APP_BACKEND_URI}/university/get-basic-info/${props.univId}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 }
//             });
//             if (!response.ok) {
//                 throw new Error('Failed to fetch university details');
//             }
//             const data = await response.json();
//             setBasicDetails(data);
//             setCanSave(true);
//             console.log('UnivId:', data.univId);
//         } catch (error) {
//             console.error('Error fetching university details:', error);
//         }
//     }

//     const updateBasicInfo = async () => {
//         if (!canSave) return;
//         try {
//             console.log('Updating Database', basicDetails);
//             const response = await fetch(`${REACT_APP_BACKEND_URI}/university/update-basic-info`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     idToken: props.idToken,
//                     updateData: basicDetails,
//                 }),
//             });
//             if (!response.ok) {
//                 throw new Error('Failed to update university details');
//             }
//             const data = await response.json();
//             console.log('Updated university details:', data);
//         } catch (error) {
//             console.error('Error updating university details:', error);
//         }
//     };

//     async function updateAll(pname, pdescription, pcity, pstate, contactArray, emailArray) {
//         setBasicDetails(prevData => ({
//             ...prevData,
//             univId: basicDetails.univId,
//             name: pname,
//             description: pdescription,
//             profileImg: basicDetails.profileImg,
//             bannerImg: basicDetails.bannerImg,
//             city: pcity,
//             state: pstate,
//             email: emailArray,
//             phone: contactArray,
//             website: null,
//         }));
//     }

//     const handleLocalEdit = () => {
//         const pname = document.getElementById('profileUnivName').value;
//         const pdescription = document.getElementById('profileDescription').value;
//         const pcity = document.getElementById('profilecity').value;
//         const pstate = document.getElementById('profileState').value;
//         let pcontact = document.getElementById('profileContact').value.trim();
//         let pemail = document.getElementById('profileEmail').value.trim();

//         pcontact = pcontact.replace(/\s+/g, ' ');
//         let contactArray = pcontact.split(',').map(item => item.trim());

//         pemail = pemail.replace(/\s+/g, ' ');
//         let emailArray = pemail.split(',').map(item => item.trim());

//         updateAll(pname, pdescription, pcity, pstate, contactArray, emailArray);
//     }

//     const fetchNumbers = async () => {
//         const concatenatedNumbers = basicDetails.phone && basicDetails.phone.length > 0
//             ? basicDetails.phone.filter(number => number && number.trim() !== '').join(', ')
//             : '';
//         setPhoneNumbers(concatenatedNumbers);

//         const concatenatedEmails = basicDetails.email && basicDetails.email.length > 0
//             ? basicDetails.email.filter(email => email && email.trim() !== '').join(', ')
//             : '';
//         setEmails(concatenatedEmails);
//     }

//     useEffect(() => {
//         fetchBasicInfo();
//     }, [props.univId]);

//     useEffect(() => {
//         fetchNumbers();
//         console.log('Basic Details Updated:', basicDetails);
//         updateBasicInfo();
//     }, [basicDetails]);

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         if (file && file.type.startsWith('image/')) { // Check if file is an image
//             setSelectedFile(file);
//             console.log(file);
//         } else {
//             alert('Please select an image file');
//             event.target.value = ''; // Clear the input if invalid file type is selected
//         }
//     };

//     const handleUploadImg = async () => {
//         if (!selectedFile) {
//             alert('No file selected');
//             return;
//         }
//         if (!canSave) {
//             alert('Couldn\'t save the data. Please try again');
//             return;
//         }
//         const formData = new FormData();
//         formData.append('file', selectedFile);
//         formData.append('idToken', props.idToken);
//         formData.append('imgType', imgType);

//         try {
//             setUploading(true);
//             const response = await axios.post(`${REACT_APP_BACKEND_URI}/university/upload`, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             console.log(response.data);
//             alert('File uploaded successfully');

//             const url = URL.createObjectURL(selectedFile);
//             if (imgType === 'profileImg') setProfileImageUrl(url);
//             else setBannerImageUrl(url);

//             setUploading(false);

//         } catch (error) {
//             console.error('Error uploading file:', error);
//             alert('Error uploading file');
//             setUploading(false);
//         }
//     };

//     const uploadImgOpen = (typeImg) => {
//         console.log('Upload Image');

//         setImgType(typeImg);

//         const box = document.getElementsByClassName('profileImgUpload');
//         if (box.length > 0) {
//             const isCurrentlyVisible = box[0].style.display === 'flex';

//             for (let i = 0; i < box.length; i++) {
//                 box[i].style.display = isCurrentlyVisible ? 'none' : 'flex';
//             }
//         }
//     }

//     const toggleDisplay = () => {
//         let orig = document.getElementById('basicInfo');
//         let cont  = document.getElementById('basicEditContainer');

//         if (!editMode) {
//             let name = document.getElementById('profileUnivName');
//             let description = document.getElementById('profileDescription');
//             let city = document.getElementById('profilecity');
//             let state = document.getElementById('profileState');
//             let contact = document.getElementById('profileContact');
//             let email = document.getElementById('profileEmail');

//             name.value = basicDetails.name;
//             description.value = basicDetails.description;
//             city.value = basicDetails.city;
//             state.value = basicDetails.state;
//             contact.value = basicDetails.phone ? basicDetails.phone.join(', ') : '';
//             email.value = basicDetails.email ? basicDetails.email.join(', ') : '';

//             cont.style.display = 'block';
//             orig.style.display = 'none';
//         } else {
//             handleLocalEdit();
//             cont.style.display = 'none';
//             orig.style.display = 'block';
//         }
//         setEditMode(!editMode);
//         fetchNumbers();
//     }

//     function handleInputChange(event) {
//         const { id, value } = event.target;
//         let targetId = '';

//         switch (id) {
//             case 'profileUnivName':
//                 targetId = 'basicName';
//                 break;
//             case 'profileDescription':
//                 targetId = 'basicDescription';
//                 break;
//             case 'profilecity':
//                 targetId = 'basiccity';
//                 break;
//             case 'profileState':
//                 targetId = 'basicState';
//                 break;
//             case 'profileContact':
//                 targetId = 'basicContact';
//                 break;
//             case 'profileEmail':
//                 targetId = 'basicEmail';
//                 break;
//             default:
//                 console.warn('Unhandled input ID:', id);
//                 return;
//         }

//         const targetElement = document.getElementById(targetId);
//         if (targetElement && value) {
//             targetElement.innerHTML = value;
//         } else {
//             console.error('Element not found for ID:', targetId);
//         }
//     }

//     return (
//         <div className="basicInfoContainer">
//             <div className="profilebanner">
//                 <div className="profileImgUpload">
//                     <input type="file" accept="image/*" onChange={handleFileChange} />
//                     <button onClick={handleUploadImg} disabled={uploading}>
//                         {uploading ? 'Uploading...' : 'Upload'}
//                     </button>
//                 </div>
//                 {basicDetails.bannerImg != null && <img src={basicDetails.bannerImg} alt="Banner" />}
//                 {!props.readonly && <span className="editBut" onClick={() => uploadImgOpen('bannerImg')}>
//                     <i className="fa-solid fa-pen-nib fa-la"></i>
//                 </span>}
//             </div>
//             <div className="profileImg">
//                 {basicDetails.profileImg != null && <img src={basicDetails.profileImg} alt="Profile" />}
//                 {!props.readonly && <span className="editBut" onClick={() => uploadImgOpen('profileImg')}>
//                     <i className="fa-solid fa-pen-nib fa-la"></i>
//                 </span>}
//             </div>
//             <div className="basicInfo" id='basicInfo'>
//                 {!props.readonly && <span className="editBut" onClick={toggleDisplay}>
//                     <i className="fa-solid fa-pen-nib fa-la"></i>
//                 </span>}
//                 <div className="name" id='basicName'>
//                     {basicDetails.name && <span>{basicDetails.name}</span>}
//                 </div>
//                 <div className="description" id='basicDescription'>
//                     {props.description && props.description !== '' && <div>{props.description}</div>}
//                 </div>
//                 <div className="city contact">
//                     <span className='contactInfo'>Address: </span>
//                     <span id='basiccity'>{basicDetails.city}</span>,
//                     <span id='basicState'>{basicDetails.state}</span>
//                 </div>
//                 <div className="contact">
//                     <span className='contactInfo'>Contact: </span>
//                     <span className='contactValue' id='basicContact'>
//                         {basicDetails.phone && basicDetails.phone.length > 0 ? (
//                             basicDetails.phone.filter(number => number && number.trim() !== '').map((number, index) => {
//                                 return index === basicDetails.phone.length - 1 ? number : number + ', ';
//                             })
//                         ) : <></>}
//                     </span>
//                     <br />
//                     <span className='contactInfo'>Email: </span>
//                     <span className='contactValue' id='basicEmail'>
//                         {basicDetails.email && basicDetails.email.length > 0 ? (
//                             basicDetails.email.filter(email => email && email.trim() !== '').map((email, index) => {
//                                 return index === basicDetails.email.length - 1 ? email : email + ', ';
//                             })
//                         ) : <></>}
//                     </span>
//                 </div>
//             </div>

//             <div id="basicEditContainer">
//                 <span className="editBut" onClick={toggleDisplay}>
//                     <i className="fa-solid fa-check fa-la"></i>
//                 </span>
//                 <form className="editContainer">
//                     <label htmlFor="univname">University Name: </label>
//                     <input type="text" id="profileUnivName" name="univname" placeholder="University Name" onInput={handleInputChange} />
//                     <label htmlFor="description">Description: </label>
//                     <textarea id="profileDescription" name="description" placeholder="Description" onInput={handleInputChange} />
//                     <label htmlFor="city">Address: </label>
//                     <input type="text" id="profilecity" name="location" placeholder="city" onInput={handleInputChange} />
//                     <label htmlFor="state">State: </label>
//                     <input type="text" id="profileState" name="state" placeholder="State" onInput={handleInputChange} />
//                     <label htmlFor="contact">Contact: </label>
//                     <input type="text" id="profileContact" name="contact" placeholder="Contact" onInput={handleInputChange} />
//                     <label htmlFor="contact">Email Ids: </label>
//                     <input type="text" id="profileEmail" name="email" placeholder="Email IDs" onInput={handleInputChange} />
//                 </form>
//             </div>
//         </div>
//     );
// }

// export default BasicInfo;
