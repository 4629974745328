import React from 'react';
import {  NavLink} from 'react-router-dom';
import { auth } from '../firebaseConfig';

const Sidebar = (props) => {
    
    const minimiseSidebar = ()=>{
        let sidebar = document.getElementById('sidebar');
        let sideNames = document.getElementsByClassName('sideNames');
        let main = document.getElementsByClassName('main')[0];
        let navbar = document.getElementsByClassName('newNavbar')[0];
        let mainCover = document.getElementById('mainCover');
        console.log('Chhotuu worked...');


        
        mainCover.style.display = 'none';
        
        if(sidebar.style.width==='200px'){
            for(let i=0; i<sideNames.length; i++){
                sideNames[i].style.display = 'none';
            }
            if(window.innerWidth>720){
              main.style.marginLeft = '60px';
              sidebar.style.width = '60px';
              navbar.style.paddingLeft = '60px';
            }
            else{
              main.style.marginLeft = '0px';
              sidebar.style.width = '0px';
              navbar.style.paddingLeft = '0px';
            }
        }
        else {
            for(let i=0; i<sideNames.length; i++){
                sideNames[i].style.display = 'block';
            }
            sidebar.style.width = '200px';
            console.log(sidebar.style.position);
            if(window.innerWidth>720){
                main.style.marginLeft = '200px';
                navbar.style.paddingLeft = '200px';
            }
        }
    }

  return (
    <>  
        <div className="sidebar" id='sidebar'>
            <div className="sidebarContainer">
                <span onClick={minimiseSidebar}>
                    <div><i class="fa-solid fa-bars fa-lg"></i></div>
                    <div className='sideNames'>Dashboard</div>
                </span>
            </div>

            <div className="sidebarContainer">
                <NavLink to="/"> <div><i className="fa-solid fa-home fa-sm"></i></div> <div className='sideNames'>Home</div> </NavLink>
                {props.specialType!=null && props.specialType==true && <NavLink to="/rankings"> <div><i className="fa-solid fa-ranking-star fa-sm"></i></div> <div className='sideNames'>Universities</div> </NavLink> }
            </div>
            {/* <div className="sidebarContainer">
                <NavLink to="/"> <div><i className="fa-solid fa-home fa-sm"></i></div> <div className='sideNames'>Home</div> </NavLink>
                {props.specialType!=null && props.specialType==true && <NavLink to="/rankings"> <div><i className="fa-solid fa-ranking-star fa-sm"></i></div> <div className='sideNames'>Universities</div> </NavLink> }
            </div> */}
    
    
            {/* <div className="sidebarContainer">
                <NavLink to="/" onClick={minimiseSidebar}>
                    <div><i class="fa-solid fa-gear fa-lg"></i></div>
                    <div className='sideNames'>Settings</div>
                </NavLink>
            </div> */}
            
            {props.specialType!=null && props.specialType==false && <div className="sidebarContainer" style={{ display: props.isLogin ? 'block' : 'none' }}>
                <NavLink to="/profile" >
                    <div><i class="fa-solid fa-user fa-lg"></i></div>
                    <div className='sideNames'>Profile</div>
                </NavLink>
            </div>}
            
            <div className="sidebarContainer" style={{ display: !props.isLogin ? 'block' : 'none' }}>
                <NavLink to="/login" >
                    <div><i class="fa-solid fa-right-to-bracket fa-lg"></i></div>
                    <div className='sideNames'>Login</div>
                </NavLink>
            </div>

            <div className="sidebarContainer" style={{ display: props.isLogin ? 'block' : 'none' }}>
                <NavLink to="/login" onClick={()=>{
                    auth.signOut().then(() => {
                        console.log('User signed out');
                        props.setLogin(false);
                        alert('User signed out');


                      }).catch((error) => {
                        console.error('Error:', error);
                      }
                    );
                }}>
                    <div><i class="fa-solid fa-right-to-bracket fa-lg"></i></div>
                    <div className='sideNames'>Logout</div>
                </NavLink>
            </div>
        </div>
    </>
  );
}

export default Sidebar;