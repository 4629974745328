import React, { useState, useEffect } from 'react';
import "../../css/Upload.css"
// import sampleData from "../../db/sampleData.json";
import GoUp from '../GoUp';
import { useLocation, useParams } from 'react-router-dom';
import sampleData from '../../db/newjson.json';
import BasicInfo from '../ProfilePage/BasicInfo';
import ProfileSection from '../ProfilePage/ProfileSection';
import PlacementSection from '../ProfilePage/PlacementSection';
import LoadingSpinner from '../../Loader/LoadingSpinner';
import LatestEvents from '../ProfilePage/LatestEvents';
import AdditionalParams from '../ProfilePage/AdditionalParams';
import Research from '../ProfilePage/Research';
import Acadamic from '../ProfilePage/Acadamic';
import Placement from '../ProfilePage/Placement';


const InstituteDetails = (props) => {

    const {univId} = useParams();
  
    if(univId==null){
      return <LoadingSpinner />
    } 

    return (
      <div>
        <div className="uploadContainer">
          
          <BasicInfo
            title='Basic Info'
            univId = {univId}
            special = {false}
            idToken = {props.idToken}
            readonly = {true}
          />
  
          <ProfileSection          
            title='Best Practices'
            param = 'bestPractices'
            special = {false}
            univId = {univId}
            idToken = {props.idToken}
            readonly = {true}
          /> 
          <Acadamic          
            title='Academic Excellence'
            param = 'academicExcellence'
            special = {false}
            univId = {univId}
            idToken = {props.idToken}
            readonly = {true}
          /> 
          <Research          
            title='Research and Innovation'
            param = 'researchExcellence'
            special = {false}
            univId = {univId}
            idToken = {props.idToken}
            readonly = {true}
          /> 
          <Placement          
            title='Placement'
            param = 'placement'
            special = {false}
            univId = {univId}
            idToken = {props.idToken}
            readonly = {true}
          />  
  
        <LatestEvents
          univId = {univId}
          idToken = {props.idToken}
          readonly = {true}
        />

        <AdditionalParams
          title=''
          description = ''
          point1 = 'point1'
          point2 = 'point2'
          link = ''
          readonly = {true}
        />
        <div id="furtherParams"></div>

        <AdditionalParams
          title=''
          description = ""
          point1 = 'point1'
          point2 = 'point2'
          link = ''
          
          readonly = {true}
        />

          <GoUp/>


        </div>
      </div>
    );
}

export default InstituteDetails;
