import React, { useEffect, useState } from "react";
import "../../css/Placement.css";
import { REACT_APP_BACKEND_URI } from "../../config";

const ResearchSection = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(true);

  const yearMap = {
    1: "Research Publication",
    2: " ",
  };

  const fullLink =
    `${link}`.startsWith("http://") || `${link}`.startsWith("https://")
      ? `${link}`
      : `http://${link}`;

  const fetchResearchRecords = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URI}/university/get-Research-records-by-year/${props.univId}/${props.index}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Research records");
      }
      const data = await response.json();
      console.log("Research records data:", data);
      setLoading(false);
      setDescription(data.description.replace(/\n/g, "<br>"));
      setLink(data.link);
    } catch (error) {
      console.error("Error fetching Research records: ", error);
    }
  };

  const uploadResearchLink = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URI}/university/update-Research-link`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idToken: props.idToken,
            updateData: {
              description: description,
              link: link,
            },
            index: props.index,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to upload Research link");
      }
      const data = await response.json();
      console.log("Research link uploaded:", data);
      alert("Research link uploaded successfully");
    } catch (error) {
      alert("Failed to upload Research link");
      console.error("Error uploading Research link: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (event) => {
    const updatedDescription = event.target.value;
    // Replace newline characters with <br> tags
    setDescription(updatedDescription.replace(/\n/g, "<br>"));
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleEditClick = () => {
    if (isEditing) {
      uploadResearchLink();
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    fetchResearchRecords();
  }, []);

  return (
    <div className="ResearchSection">
      <div className="ResearchTitle"><b>{yearMap[props.index]}</b></div>
      <small>
        <em>[Kindly upload your Research publications summary.]</em>
      </small>
	  <div></div>
      <div className="ResearchLinkSection">
        {isEditing ? (
          <div className="editingSection">
            <textarea
              value={description.replace(/<br>/g, "\n")}
              onChange={handleDescriptionChange}
              placeholder="Enter a description"
              rows={2}
            />
            <input
              type="text"
              value={link}
              onChange={handleLinkChange}
              placeholder="Enter linktree link"
            />
          </div>
        ) : (
          <div className="displaySection">
            <div
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            {link && link.length > 0 && (
              <button
                className="checkLinkButton"
                onClick={() =>
                  window.open(fullLink, "_blank", "noopener,noreferrer")
                }
              >
                Read More
              </button>
            )}
          </div>
        )}

        {!props.readonly && (
          <div className="editBut">
            <button
              className={`toggleButton ${isEditing ? "tick" : "edit"}`}
              onClick={handleEditClick}
              disabled={loading}
            >
              <i className={`fa-solid fa-${isEditing ? "check" : "pen-nib"} fa-la`}></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResearchSection;
