// LoadingSpinner.js
import React from 'react';
import { Circles } from 'react-loader-spinner';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
        <Circles 
            color="#394647"
            height={80} width={80}
            // colors={["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF"]}
        />
    </div>
  );
};

export default LoadingSpinner;
