import React from 'react';

import HomePageUniversity from '../HomePage/HomePageUniversity';
import homeUniversitiesData from "../../db/homeUniversityData.json";


const InstituteCarousel = () => {

  return (
    <>  
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">

            {homeUniversitiesData.map((university, index) => (
              
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval="2500" key={index}>
                <HomePageUniversity name={university.name} 
                  bestPractices = {university.bestPractices}
                  academicExcellence = {university.academicExcellence}
                  researchExcellence = {university.researchExcellence}
                  placementRecords = {university.placementRecords}
                  innovation = {university.innovation}
                  buttonLink = "/institutions"
                />
              </div>
            ))}
            
          </div>
          
        </div>
        
        {/* <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a> */}
    
        
    </>
  );
}

/* <div class="carousel-item active" data-bs-interval=" ">
              <div className="flexing">
                <InstituteCard/>
              </div>
            </div>
            <div class="carousel-item active" data-bs-interval="2000">
              <div className="flexing">
                <InstituteCard/>
              </div>
            </div> 
            
            <div class="carousel-item" data-bs-interval="2000">
              <div className="flexing">
                <div className='rightblock'>
                  <img src={image2} alt="..."/>
                  <AboutInstitute name="Graphic Era University"/>
                </div>
                <div className='leftblock'>
                    <div class="about-text">
                        <h1 class="animated animated-text">
                            <span class="mr-2">Graphic Dashboard</span>
                            <div class="animated-info">
                                <span className='subheading'>Data-Driven Insights | University Rankings | Event Updates </span>
                            </div>
                        </h1>
                        <p>A centralized platform offering data, rankings, and event updates for universities across the Uttarakhand region</p>                          
                    </div>
                </div>
              </div>
            </div> */


export default InstituteCarousel;
