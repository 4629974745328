import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HomePageUniversity = (props) => {
    const [butLink, setButLink] = useState('#bestPractices');

    const handleParams = (e, idx) => {
        let params = document.getElementsByClassName('params');
        let heading = document.getElementsByClassName('contentHeading');
        let content = document.getElementsByClassName('paramContent');
        let hider = document.getElementsByClassName('hider');

        for (let i = 0; i < params.length; i++) {
            params[i].classList.remove('active');
            if (i === idx) {
                params[i].classList.add('active');
            }
        }

        for (let i = 0; i < hider.length; i++) hider[i].style.width = "100%";
        setTimeout(() => {
            for (let i = 0; i < hider.length; i++)
                hider[i].style.width = "0%";

            switch (idx) {
                case 0:
                    for (let i = 0; i < content.length; i++) {
                        content[i].innerHTML = props.bestPractices;
                        heading[i].textContent = "Best Practices";
                        setButLink('#bestPractices');
                    }
                    break;
                case 1:
                    for (let i = 0; i < content.length; i++) {
                        content[i].innerHTML = props.academicExcellence;
                        heading[i].textContent = "Academic Excellence";
                        setButLink('#academicExcellence');
                    }
                    break;
                case 2:
                    for (let i = 0; i < content.length; i++) {
                        content[i].innerHTML = props.researchExcellence;
                        heading[i].textContent = "Research Excellence";
                        setButLink('#researchExcellence');
                    }
                    break;
                case 3:
                    for (let i = 0; i < content.length; i++) {
                        content[i].innerHTML = props.placementRecords;
                        heading[i].textContent = "Placement Records";
                        setButLink('#placementRecords');
                    }
                    break;
                case 4:
                    for (let i = 0; i < content.length; i++) {
                        content[i].innerHTML = props.innovation;
                        heading[i].textContent = "Innovation";
                        setButLink('#innovation');
                    }
                    break;
                default:
            }
        }, 600);
    }

    return (
        <div className="homePageContainer">
            <div className="homeParaParent">
                <div className="paraButtons">
                    <span className="params active" onMouseOver={(e) => handleParams(e, 0)}>Best Practices</span>
                    <span className="params" onMouseOver={(e) => handleParams(e, 1)}>Academic Excellence</span>
                    <span className="params" onMouseOver={(e) => handleParams(e, 2)}>Research Excellence</span>
                    <span className="params" onMouseOver={(e) => handleParams(e, 3)}>Placement Records</span>
                    {/* <span className="params" onMouseOver={(e) => handleParams(e, 4)}>Innovation</span> */}
                </div>
                <div className="homeparameters">
                    <div className="paraContent">
                        <h4 className='contentHeading'>Best Practices</h4>
                        <p className='paramContent'>
                            {props.bestPractices}
                        </p>
                    </div>
                    <div className="hider"></div>
                </div>
            </div>
            <div className="fullScreenCard">
                <div id='universityName'>{props.name}</div>
                {/* Remove the EventPost components */}
            </div>
        </div>
    );
}

export default HomePageUniversity;