import React from 'react';
import '../css/Login.css';




const Contact = () => {
  return (
    <div className="register-container">
      <form className="register-form">
        <div className="logo"></div>
        <div className="form-field">
          <input type="text" id="name" name="name" placeholder='Name' required />
        </div>
        <div className="form-field">
          <input type="email" id="email" name="email" placeholder='Email' required />
        </div>
        <div className="form-field">
          <textarea id="message" name="message" rows="4" placeholder='Message' required></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
}

export default Contact;