import React, {useState, useEffect} from 'react';
import BasicInfo from '../components/ProfilePage/BasicInfo';
import ProfileSection from '../components/ProfilePage/ProfileSection';
import PlacementSection from '../components/ProfilePage/PlacementSection';
import "../css/Upload.css"
import '../css/Loader.css';
import sampleData from '../db/newjson.json';
import {auth} from '../firebaseConfig';
import LoadingSpinner from '../Loader/LoadingSpinner';
import LatestEvents from '../components/ProfilePage/LatestEvents';
import GoUp from '../components/GoUp';
import Publication from '../components/ProfilePage/Publications';
import Placement from '../components/ProfilePage/Placement';
import Research from '../components/ProfilePage/Research';
import Acadamic from '../components/ProfilePage/Acadamic';


const Upload = (props) => {
  const [loading, setLoading] = useState(false);

  if(loading || props.univId==null){
    return <LoadingSpinner/>
  }

  return (
    <div>
      <div className="uploadContainer">
        
        <BasicInfo
          title='Basic Info'
          univId = {props.univId}
          idToken = {props.idToken}
          readonly = {false}
        />

        <ProfileSection          
          title='Best Practices'
          param = 'bestPractices'
          univId = {props.univId}
          special = {false}
          idToken = {props.idToken}
          readonly = {false}
        /> 
        <Acadamic          
          title='Academic Excellence'
          param = 'academicExcellence'
          univId = {props.univId}
          special = {false}
          idToken = {props.idToken}
          readonly = {false}
        /> 
        <Research          
          title='Research and Innovation'
          param = 'researchExcellence'
          special = {false}
          univId = {props.univId}
          idToken = {props.idToken}
          readonly = {false}
        /> 

        <Placement          
          title='Placement'
          param = 'placement'
          special = {false}
          univId = {props.univId}
          idToken = {props.idToken}
          readonly = {false}
        />  

        <LatestEvents
          univId = {props.univId}
          idToken = {props.idToken}
          readonly = {false}
        />

      
        {/* <Publication
          univId = {props.univId}
          idToken = {props.idToken}
          readonly = {false}
        /> */}
      
        {/* <span class='saveDataBut' onClick={handleSave}>
          <i className='fa-solid fa-floppy-disk fa-la'></i>
        </span>

        <span class='saveDataBut refresh' onClick={refresh}>
          <i className='fa-solid fa-arrows-rotate fa-la'></i>
        </span> */}
        
        <GoUp/>

      </div>
    </div>
  );
}

export default Upload;