import React from "react";
import { useState, useEffect} from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../css/Loader.css'
import { REACT_APP_BACKEND_URI } from "../../config";

const ProfileSection = (props) => {
    const [content, setContent] = useState(props.content);
    const [tempContent, setTempContent] = useState(content); 
    const [isEditable, setIsEditable] = useState(false);
    const [expand, setExpand] = useState(false);
    const [loading, setLoading] = useState(true);
    const [point1, setPoint1] = useState();
    const [point2, setPoint2] = useState('');
    const [link, setLink] = useState('');

    const [data, setData] = useState(null);
    
    const fetchParamByTitle = async () => {
        console.log('Fetching data for:', props.univId);
        try {
            const response = await fetch(`${REACT_APP_BACKEND_URI}/university/get-parameter/${props.univId}/${props.param}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch university details');
            }
            const data = await response.json();
            console.log('Data:', data);
            setData(data);
            setPoint1(data.points[0]);
            setPoint2(data.points[1]);
            setLink(data.link);
            setContent(data.description);
            setTempContent(data.description);
            setLoading(false);
        }
        catch (error) {
            console.error('Error fetching university details:', error);
        }
    }

    const updateParamByTitle = async () => {
        if(loading || data==null) return;
        console.log('Updating data for:', {
            description: tempContent,
            points: [point1, point2],
            link: link,
        });

        const updatedData = {
            description: tempContent,
            points: [point1, point2],
            link: link
        };
        setData(updatedData);
        try {
            setLoading(true);
            const response = await fetch(`${REACT_APP_BACKEND_URI}/university/update-parameters-by-type`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idToken: props.idToken,
                    updateData: updatedData,
                    param: props.param,
                }),
                });
            if (!response.ok) {
                throw new Error('Failed to update university details');
            }
            const data = await response.json();
            console.log('Updated university details:', data);
        } catch (error) {
            console.error('Error updating university details:', error);
            alert("Oops! Couldn't save the data. Please try again.")
        }
        setLoading(false);
        setExpand(false);
    }

    // Inside your component
    useEffect(() => {
        if(isEditable) setExpand(true);
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if (isEditable) {
                    toggleEditing();
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isEditable]);

    
    useEffect(() => {
        if(props.special) return;
        fetchParamByTitle();
    }, [props.univId, props.param]);
    
    const updateContent = () => {
        setContent(tempContent);
        updateParamByTitle();
    }

    const updateTempContent = async (e, idx)=> {
        if(idx==0) await setTempContent(e.target.textContent);
        else if(idx==1) await setPoint1(e.target.textContent);
        else if(idx==2) await setPoint2(e.target.textContent);

    }
    const toggleEditing = () => {
        if(isEditable){
            updateContent();
        }
        setIsEditable(!isEditable);
    }

    const expanding = () => {   
        setExpand(!expand);
    }



    return (
        <>
        <section className={`profileSection`} onClick={()=>{
                if(!isEditable)
                expanding();
            }}>
            {/* {link && link.length>0? <legend href={link} target="_blank" rel="noreferrer">:<legend>} */}
            <legend onClick={
                ()=>{
                    window.open(props.link, '_blank', 'noopener,noreferrer');
                }
            }> {isEditable ? 'Editing' : props.title } {
                link!=null && link.length>0 &&
                <i class="fa-solid fa-link"/>}
            </legend>
            {!props.readonly && <span className="editBut" onClick={toggleEditing} > 
                <i class={isEditable?"fa-solid fa-check fa-la":"fa-solid fa-pen-nib fa-la"}></i> 
            </span>}
            <p className={isEditable?'editing':''} contentEditable={isEditable} onInput={(e)=>{
                updateTempContent(e, 0);
            }}>
               {props.description}
            </p>
        </section>
        {false && <section className={`expandedParameter ${expand ? 'expand' : ''}`}>
            <p className={isEditable?'editing':''} contentEditable={isEditable} onInput={(e)=>{
                updateTempContent(e, 1);
            }}>
                {data!=null && data.points.length>=1 && data.points[0].trim().length>0 && <li>{data.points[0]}</li>}
            </p>
            <p className={isEditable?'editing':''} contentEditable={isEditable} onInput={(e)=>{
                updateTempContent(e, 2);
            }}>
                {data!=null && data.points.length>=2 && data.points[0].trim().length>0 && <li>{data.points[1]}</li>}
            </p>
            
            <div style={{width: "100%"}}>
                <input type='text' placeholder={link && link.length>0? link:`Add Link`} onInput={(e)=>{
                        setLink(e.target.value);
                        console.log('Link:', e.target.value);
                    }}
                    readOnly={!isEditable}
                />
            </div>
            
        </section>}
        </>
    );
}
export default ProfileSection;
