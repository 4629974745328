import React, { useEffect, useState } from 'react';

const GoUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    console.log('Scroll to top');
    // alert('Scroll to top');
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>

    {isVisible && <div className="scroll-to-top">
      
        <div onClick={scrollToTop}>
          <i class="fa-solid fa-arrow-up"></i>
        </div>
    </div>}
    </>
  );
}

export default GoUp;