import React from 'react';
import '../css/Home.css';
import InstituteDetails from '../components/InstitutePage/InstituteDetails';

const Institutions = () => {
    
  return (
    <>
        <InstituteDetails/>  
    </>
  );
}


export default Institutions;
