import React from 'react';
import '../css/Home.css';
import DegreeHat from "../res/images/governor.png";
import InstituteCarousel from '../components/InstitutePage/InstituteCarousel';
import Mission from './Mission';
import BackgroundImage from '../res/images/b.jpg'; // Import the background image

const Home = () => {
  const block1Style = {
    backgroundImage: `url(${BackgroundImage})`,
    
  };
  
  const Block = () => (
    <div style={block1Style}>
      {/* Content here */}
    </div>
  );
  

  return (
    <>
      <div className="block1" style={block1Style}>
        <div className="leftblock flexing">
          <img src={DegreeHat} alt="" />
        </div>
        <div className="rightblock">
          <div className="homewrapper">
            <div className="homeminiblock">
              Centralized Platform
            </div>
            <div className="homeminiblock">
              Data-driven Insights
            </div>
            <div className="homeminiblock">
              Record of Excellence
            </div>
            <div className="homeminiblock">
              Research and Innovation
            </div>
          </div>
        </div>
      </div>

      <InstituteCarousel />

      {/* <Mission/> */}
    </>
  );
}

export default Home;