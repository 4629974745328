import React from "react";
import '../css/Mission.css';
import Img1 from '../res/images/ukgovernor.jpeg'
import Img2 from '../res/images/unity-fist.png'

const Mission = () => {
    return (
        <>
        <div className="mission-container">
          <div className="owner-photo">
            <img src={Img1} alt="Owner" />
          </div>
          <div className="details-container">
            <h1>About Us</h1>
            <span className="under-bar"></span>
            <p>
              Our mission is to provide a platform where students can find all the information they need to make an informed decision about their higher education. We aim to provide a comprehensive list of universities and colleges in the UK and provide information about their courses, rankings, and other important details.
            </p>
            <p>
            We also aim to provide a platform where students can connect with other students and alumni to get a better understanding of the universities and colleges they are interested in.
            </p>
          </div>
        </div>
        <div className="mission-container">
          <div className="owner-photo">
            <img src={Img2} alt="Owner" />
          </div>
          <div className="details-container">
            <h1>Our Mission</h1>
            <span className="under-bar"></span>
            <p>
              Our mission is to provide a platform where students can find all the information they need to make an informed decision about their higher education. We aim to provide a comprehensive list of universities and colleges in the UK and provide information about their courses, rankings, and other important details.
            </p>
            <p>
            We also aim to provide a platform where students can connect with other students and alumni to get a better understanding of the universities and colleges they are interested in.
            </p>
          </div>
        </div>
        {/* <div className="mission-container">
          <div className="owner-photo">
            <img src={Img} alt="Owner" />
          </div>
          <div className="details-container">
            <h1>Our Team</h1>
            <span className="under-bar"></span>
            <p>
              Our mission is to provide a platform where students can find all the information they need to make an informed decision about their higher education. We aim to provide a comprehensive list of universities and colleges in the UK and provide information about their courses, rankings, and other important details.
            </p>
            <p>
            We also aim to provide a platform where students can connect with other students and alumni to get a better understanding of the universities and colleges they are interested in.
            </p>
          </div>
        </div> */}
        </>
      );
}

export default Mission;