import React, {useEffect, useState} from 'react';
import RecordCell from './RecordCell';
import * as XLSX from 'xlsx';
import { REACT_APP_BACKEND_URI } from '../../config';

const Publication = (props) => {
    const [isAdding, setIsAdding] = React.useState(false);
    const [records, setRecords] = useState([]);

    const fetchPlacementRecords = async () => {
        try {
            const response = await fetch(`${REACT_APP_BACKEND_URI}/university/get-placement-records/${props.univId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch placement records');
            }
            const data = await response.json();
            console.log('Placement records data:', data);
            setRecords(data);
        }
        catch (error) {
            console.error('Error fetching placement records: ', error);
        }
    }

    useEffect(() => {
        fetchPlacementRecords();
    }, []);



    const [jsonData, setJsonData] = useState(null);

    const handleFileUpload = (e) => {
        const excelFile = document.getElementById('excelFile');
        const file = excelFile.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
            
                const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const requiredColumns = ['Name', 'Company', 'Package'];
                const hasRequiredColumns = requiredColumns.every(column => sheetData[0].includes(column));

                if (!hasRequiredColumns) {
                    console.error('Sheet does not contain all required columns.');
                    alert('Sheet should contain Name, Company, and Package columns. It is case-sensitive.');
                    return; // Stop further execution if not all required columns are found
                }

                // Proceed with processing if all required columns are present
                const rawData = XLSX.utils.sheet_to_json(worksheet);
                const formattedData = formatToNestedJSON(rawData);
                setJsonData(formattedData);
                console.log(formattedData);
                
                const recordArray = records.length > 0 ? [...records] : [];
                formattedData.forEach(element => {
                    recordArray.push(element);
                });
                setRecords(recordArray);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const formatToNestedJSON = (data) => {
        return data.map(row => ({
            name: row.Name,
            company: row.Company,
            package: row.Package,
        }));
    };
    
    const insertNewRecord = () => {
        const name = document.getElementsByName('name')[0].value;
        const company = document.getElementsByName('company')[0].value;
        const pack = document.getElementsByName('package')[0].value;

        if(name.length === 0 || pack.length === 0 || company.length === 0) return;

        const newRecord = {
            name: name,
            company: company,
            package: parseInt(pack),
        }
        const recordArray = records.length > 0 ? [...records] : [];
        recordArray.push(newRecord);
        setRecords(recordArray);

        document.getElementsByName('name')[0].value = '';
        document.getElementsByName('company')[0].value = '';
        document.getElementsByName('package')[0].value = '';

    }


    return (
        <>
        {true && <table className="publicationSection">
            <thead>
                <th>Title of the Paper</th>
                <th>Author</th>
                <th>Journal/Conference</th>
                <th>Year</th>
            </thead>
            <tbody className="publicationBody">

                <div className='actualData'>
                    <tr>
                        <td>title</td>
                        <td>author</td>
                        <td>journal</td>
                        <td>year</td>
                    </tr>

                    
                    {records!=null && Array.isArray(records) && records.length > 0 && records.map((record, index) => (
                        record.name && record.company && record.package ? (
                            <RecordCell 
                                key={index}
                                name={record.name}
                                company={record.company} 
                                package={record.package}
                            />
                        ) : null
                    ))}
                </div>
                <div className='placementInputArea'>
                    <div className={isAdding ? 'recordInput active' : 'recordInput'}>
                        <div>
                            <input type="text" placeholder="Name" name='name' required/>
                        </div>
                        <div>
                            <input type="text" placeholder="Company" name='company' required/>
                        </div>
                        <div>
                            <input type="number" placeholder="Package" name='package' required/>
                        </div>
                    </div>
                    <div className={isAdding ? 'recordInput active' : 'recordInput'}>
                        <input id='excelFile' type="file" accept=".xlsx, .xls" />
                    </div>
                    {!props.readonly && <div className="addCell" onClick={()=>{
                        if(isAdding){ insertNewRecord(); handleFileUpload()}
                        setIsAdding(!isAdding);
                    }}>
                        {isAdding ? <span ><i class="fa-solid fa-check fa-la"></i></span> : <span ><i class="fa-solid fa-plus fa-la"></i></span>    }
                    </div>}
                </div>
            </tbody>
        </table>}

        
        
        </>
    );
}
export default Publication;


