import React, { useEffect, useState } from 'react';
import EventPost from "./EventPost";
import { FaCloudUploadAlt } from 'react-icons/fa';
import axios from 'axios';
import { REACT_APP_BACKEND_URI } from '../../config';

const LatestEvents = (props) => {
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [news1, setNews1] = useState(null);
    const [news2, setNews2] = useState(null);
    const [news3, setNews3] = useState(null);
    const [news4, setNews4] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');


    const fetchLatestEvents = async () => {
        try {
            const response = await fetch(`${REACT_APP_BACKEND_URI}/university/get-latest-news/${props.univId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch latest news');
            }
            const data = await response.json();
            console.log('Latest news data:', data);
            setNews1(data.news1);
            setNews2(data.news2);
            setNews3(data.news3);
            setNews4(data.news4);
        }
        catch (error) {
            console.error('Error fetching latest news: ', error);
        }
    }
    
    useEffect(() => {
        fetchLatestEvents();
    }, []);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            setFileName(file.name);
        }
    };

    const UploadPost = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('idToken', props.idToken);
        formData.append('title', title);
        formData.append('description', description);

        // eslint-disable-next-line no-restricted-globals
        let reponse = confirm('Uploading Post');
        if(!reponse) return;
      
        console.log('Uploading Post', {
            title: title,
            description: description
        })
        
        try {
            
            setNews1(null);
            setNews2(null);
            setNews3(null);
            setNews4(null);

          const response = await axios.post(`${REACT_APP_BACKEND_URI}/university/upload-post`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log(response.data);
          

        } catch (error) {
          console.error('Error uploading file:', error);
            alert('Error uploading file')   
        }
        fetchLatestEvents();
      };

    return (
        <>
            <section className="eventSection profileSection">
                <legend>Latest Events</legend>
                    <div className="eventParent">
                        <EventPost news={news1} />
                    </div>
                    <div className="eventParent">
                        <EventPost news={news2} />
                    </div>
                    <div className="eventParent">
                        <EventPost news={news3} />
                    </div>
                    <div className="eventParent">
                        <EventPost news={news4} />
                    </div>
                    
                    {!props.readonly && <div className="eventUpload">
                        <div className="file-upload-container">
                            <label htmlFor="file-upload" className="file-upload-label">
                                <FaCloudUploadAlt size={50} />
                                <p>{fileName || "Click to Upload a File"}</p>
                                <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                className="file-upload-input"
                                />
                            </label>
                        </div>    
                        <div className="eventDetails">
                            <input type="text" placeholder='Post Title' onInput={(e)=>{
                                setTitle(e.target.value);
                            }}/>
                            <textarea type="text" placeholder='Post Description' onInput={(e)=>{
                                setDescription(e.target.value);
                            }} />
                            <button onClick={UploadPost}>Post</button>

                        </div>
                        
                    </div>}
                    
            </section>
        </>
    );
}

export default LatestEvents;
