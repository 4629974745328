import React from 'react';
import '../../css/Institutes.css';
import { Link } from 'react-router-dom';

const InstituteMiniCard = ({ univId, img, name }) => {
    return (
        <Link to={`/institutions/${univId}`} className="miniCardBlock">
            <img src={img || 'path/to/default-image.png'} alt={name || 'Institute Image'} className="miniCardImg" />
            <div className="univname">
                {name || 'Institute Name'}
            </div>
        </Link>
    );
}

export default InstituteMiniCard;
