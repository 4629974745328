import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../css/Loader.css';
import { REACT_APP_BACKEND_URI } from "../../config";
import ResearchSection from "./ResearchSection";
import PlacementSection from "./PlacementSection";

const Placement = (props) => {
    const [content, setContent] = useState(props.content);
    const [tempContent, setTempContent] = useState(content); 
    const [isEditable, setIsEditable] = useState(false);
    const [expand, setExpand] = useState(false);
    const [loading, setLoading] = useState(true);
    const [point1, setPoint1] = useState('');
    const [point2, setPoint2] = useState('');
    const [link, setLink] = useState('');

    const [data, setData] = useState(null);

    const fetchParamByTitle = async () => {
        console.log('Fetching data for:', props.univId, props.param);
        try {
            const response = await fetch(`${REACT_APP_BACKEND_URI}/university/get-parameter/${props.univId}/${props.param}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch university details');
            }
            const data = await response.json();
            console.log('Data:', data);
            setData(data);
            setPoint1(data.points[0]);
            setPoint2(data.points[1]);
            setLink(data.link);
            setContent(data.description);
            setTempContent(data.description);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching university details:', error);
        }
    }

    const updateParamByTitle = async () => {
        if (loading || data == null) return;
        console.log('Updating data for:', {
            description: tempContent,
            points: [point1, point2],
            link: link,
        });

        const updatedData = {
            description: tempContent,
            points: [point1, point2],
            link: link
        };
        setData(updatedData);
        try {
            setLoading(true);
            const response = await fetch(`${REACT_APP_BACKEND_URI}/university/update-parameters-by-type`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idToken: props.idToken,
                    updateData: updatedData,
                    param: props.param,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to update university details');
            }
            const data = await response.json();
            console.log('Updated university details:', data);
        } catch (error) {
            console.error('Error updating university details:', error);
            alert("Oops! Couldn't save the data. Please try again.")
        }
        setLoading(false);
        setExpand(false);
    }

    useEffect(() => {
        if (isEditable) setExpand(true);
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if (isEditable) {
                    toggleEditing();
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isEditable]);

    useEffect(() => {
        console.log('Props changed:', props.univId, props.param);
        fetchParamByTitle();
    }, [props.univId, props.param]);

    const updateContent = () => {
        setContent(tempContent);
        updateParamByTitle();
    }

    const toggleEditing = () => {
        if (isEditable) {
            updateContent();
        }
        setIsEditable(!isEditable);
    }

    const expanding = () => {   
        setExpand(!expand);
    }

    if (loading) {
        return (
            <>
                <section className="profileSection">
                    <legend>Loading...</legend>
                    <Skeleton className="custom-skeleton" height={20} count={1} />
                    <Skeleton className="custom-skeleton" height={20} count={1} />
                </section>
                <section className={`expandedParameter ${expand ? 'expand' : ''}`}>
                </section>
            </>
        )
    }
const legendClick = () => {
        // if (props.param === 'researchExcellence') {
        //     window.location.hash = 'furtherParams';
        //     return;
        // }
        // if (link != null && link.length > 0) {
        //     window.open(link, '_blank', 'noopener,noreferrer');
        // }
}
    return (
        <>
            <section className="profileSection" onClick={() => {
                if (!isEditable)
                    expanding();
            }}>
                <legend onClick={legendClick}> {isEditable ? 'Editing' : props.title} {
                    link != null && link.length > 0 &&
                    <i className="fa-solid fa-link" />}
                </legend>
              <PlacementSection
                index='1'
                year='2024'
                univId = {props.univId}
                idToken = {props.idToken}
                readonly = {props.readonly}
              />
              {/* <PlacementSection
                index='2'
                year='2023'
                univId = {props.univId}
                idToken = {props.idToken}
                readonly = {props.readonly}
              />
              <PlacementSection
                index='3'
                year='2022'
                univId = {props.univId}
                idToken = {props.idToken}
                readonly = {props.readonly}
              /> */}
            </section>
        </>
    );
}

export default Placement;
