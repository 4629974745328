import React, { useEffect, useState } from "react";
import "../../css/Placement.css";
import { REACT_APP_BACKEND_URI } from "../../config";

const PlacementSection = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(true);

  const yearMap = {
    1: "Placement",
    2: " ",
  };

  const fullLink =
    `${link}`.startsWith("http://") || `${link}`.startsWith("https://")
      ? `${link}`
      : `http://${link}`;

  const fetchPlacementRecords = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URI}/university/get-Placement-records-by-year/${props.univId}/${props.index}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch placement records");
      }
      const data = await response.json();
      console.log("Placement records data:", data);
      setLoading(false);
      setDescription(data.description.replace(/\n/g, "<br>")); // Convert newlines to <br>
      setLink(data.link);
    } catch (error) {
      console.error("Error fetching placement records: ", error);
    }
  };

  const uploadPlacementLink = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URI}/university/update-Placement-link`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idToken: props.idToken,
            updateData: {
              description: description,
              link: link,
            },
            index: props.index,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to upload placement link");
      }
      const data = await response.json();
      console.log("Placement link uploaded:", data);
      alert("Placement link uploaded successfully");
    } catch (error) {
      alert("Failed to upload placement link");
      console.error("Error uploading placement link: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (event) => {
    // Update description and replace newlines with <br> tags for HTML rendering
    const updatedDescription = event.target.value.replace(/\n/g, "<br>");
    setDescription(updatedDescription);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleEditClick = () => {
    if (isEditing) {
      uploadPlacementLink();
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    fetchPlacementRecords();
  }, []);

  return (
    <div className="PlacementSection">
      <div className="PlacementTitle"><b>{yearMap[props.index]}</b></div>
      <small>
        <em>[Kindly upload your Placement summary]</em>
      </small>
      <div className="PlacementLinkSection">
        {isEditing ? (
          <div className="editingSection">
            <textarea
              value={description.replace(/<br>/g, "\n")} // Convert <br> tags back to newlines for editing
              onChange={handleDescriptionChange}
              placeholder="Enter a description"
              rows={2}
            />
            <input
              type="text"
              value={link}
              onChange={handleLinkChange}
              placeholder="Enter linktree link"
            />
          </div>
        ) : (
          <div className="displaySection">
            <div dangerouslySetInnerHTML={{ __html: description }} /> {/* Render HTML safely */}
            {link && link.length > 0 && (
              <button
                className="checkLinkButton"
                onClick={() =>
                  window.open(fullLink, "_blank", "noopener,noreferrer")
                }
              >
                Read More
              </button>
            )}
          </div>
        )}

        {!props.readonly && (
          <div className="editBut">
            <button
              className={`toggleButton ${isEditing ? "tick" : "edit"}`}
              onClick={handleEditClick}
              disabled={loading}
            >
              <i className={`fa-solid fa-${isEditing ? "check" : "pen-nib"} fa-la`}></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlacementSection;
