// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCcO63i_cmBZB4Rg_Z1hMoevVMl0yg7jZ0",
  authDomain: "uni-saransh.firebaseapp.com",
  projectId: "uni-saransh",
  storageBucket: "uni-saransh.appspot.com",
  messagingSenderId: "464421410455",
  appId: "1:464421410455:web:98d3c3ac3335bd1010fbd2",
  measurementId: "G-L5ZTJC945N"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from 'firebase/auth';

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAJuvZC0ElHK5NY_Ms6SXwKFU4k5BhPc-o",
//   authDomain: "new-unisaraansh.firebaseapp.com",
//   projectId: "new-unisaraansh",
//   storageBucket: "new-unisaraansh.appspot.com",
//   messagingSenderId: "97361308208",
//   appId: "1:97361308208:web:23c39cbc455b62ffef9bf5",
//   measurementId: "G-98V8J8BSZW"
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

// export { app, auth };