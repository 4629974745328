import React, {useEffect, useState} from "react";
import Img from '../../res/images/GEU.jpeg';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../css/Loader.css'



const EventPost = ({news}) => {
    const [loading, setLoading] = useState(false);

    if(news === null || loading) {
        return (
            <div className="eventPost">
                <div className="eventImg">
                    <Skeleton className="custom-skeleton" height={90} count={1} />
                </div>
                <div className="eventDetails">
                <Skeleton className="custom-skeleton" height={16} count={1} />
                <Skeleton className="custom-skeleton" height={16} count={1} />
                <Skeleton className="custom-skeleton" height={16} count={1} />                
                </div>
            </div>
        );
    }

    return (
        <div className="eventPost">
            <div className="eventImg">
                <a href={news.link} target="_new"><img src={news.link} alt="Event" /></a>
            </div>
            <div className="eventDetails">
                <div className="eventPostTitle">{news.title}</div>
                <div className="eventPostDescription">
                    {news.description}
                </div>
            </div>
        </div>
    );
}

export default EventPost;