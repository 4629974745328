import React, { useEffect, useState } from 'react';
import '../../css/Home.css';
import InstituteMiniCard from './InstituteMiniCard';
import LoadingSpinner from '../../Loader/LoadingSpinner';
import noProfile from './noProfile.png';
import { REACT_APP_BACKEND_URI } from '../../config';

const Rankings = () => {
  const [universitiesData, setUniversitiesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchUniversities();
  }, []);

  const fetchUniversities = async () => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URI}/university/get-universities`);
      if (!response.ok) {
        throw new Error('Failed to fetch universities');
      }
      const data = await response.json();
      setUniversitiesData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching universities:', error.message);
    }
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUniversities = universitiesData?.filter(university =>
    university.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <LoadingSpinner />
  }

  // Separate the universities based on the conditions
  const specialUniversity = filteredUniversities?.find(u => u.univId === '0.kbzvewt1txEAOBQWLJYY');
  const universitiesWithNoImage = filteredUniversities?.filter(u => !u.profileImg || u.profileImg.length === 0 && u.univId !== '0.kbzvewt1txEAOBQWLJYY');
  const otherUniversities = filteredUniversities?.filter(u => u.profileImg && u.profileImg.length > 0 && u.univId !== '0.kbzvewt1txEAOBQWLJYY');

  return (
    <>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search for a university"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      
      <div className="block1">
        <div className="miniCardGrandParent">
          {/* Display the special university with univId 0.kbzvewt1txEAOBQWLJYY at the top */}
          {specialUniversity && (
            <div className="miniCardParent" key="0.kbzvewt1txEAOBQWLJYY">
              <InstituteMiniCard
                name={specialUniversity.name}
                about="About the university"
                univId='0.kbzvewt1txEAOBQWLJYY'
                img={specialUniversity.profileImg?.length > 0 ? specialUniversity.profileImg : noProfile}
              />
            </div>
          )}

          {/* Display universities with no image next */}
          {universitiesWithNoImage?.map((university, index) => (
            <div className="miniCardParent" key={university.univId}>
              <InstituteMiniCard
                name={university.name}
                about="About the university"
                univId={university.univId}
                img={noProfile}
              />
            </div>
          ))}

          {/* Display the rest of the universities */}
          {otherUniversities?.map((university, index) => (
            <div className="miniCardParent" key={university.univId}>
              <InstituteMiniCard
                name={university.name}
                about="About the university"
                univId={university.univId}
                img={university.profileImg}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Rankings;
